const words = {
    "words": [
        "Aalen",
        "Aases",
        "Abart",
        "Abbau",
        "Abend",
        "Abgas",
        "Abort",
        "Abruf",
        "Absud",
        "Abtei",
        "Abtes",
        "Abweg",
        "Abzug",
        "Achse",
        "Acker",
        "Addis",
        "Adels",
        "Adieu",
        "Adolf",
        "Adria",
        "Aebte",
        "Aeste",
        "Aetna",
        "Aexte",
        "Affes",
        "After",
        "Agave",
        "Agent",
        "Ahlen",
        "Ahorn",
        "Akaba",
        "Akten",
        "Aktie",
        "Alarm",
        "Album",
        "Algen",
        "Alkor",
        "Allah",
        "Allee",
        "Allen",
        "Alpen",
        "Alten",
        "Alter",
        "Altes",
        "Amiga",
        "Ammen",
        "Amors",
        "Ampel",
        "Amtes",
        "Anbau",
        "Andr‚",
        "Andre",
        "Angel",
        "Angst",
        "Anker",
        "Anmut",
        "Annie",
        "Anruf",
        "Anwar",
        "Apoll",
        "Appel",
        "Apple",
        "April",
        "Apsis",
        "Arden",
        "Areal",
        "Arena",
        "Argau",
        "Argus",
        "Arien",
        "Arier",
        "Armee",
        "Armen",
        "Armut",
        "Arosa",
        "Arsch",
        "Arsen",
        "Artus",
        "Asche",
        "Asind",
        "Asket",
        "Aspik",
        "Astes",
        "Asyls",
        "Atari",
        "Athen",
        "Atome",
        "Auges",
        "Aurel",
        "Autor",
        "Autos",
        "Axiom",
        "B-Dur",
        "BASIC",
        "BASIK",
        "Babel",
        "Babys",
        "Bades",
        "Baien",
        "Balls",
        "Bambi",
        "Bande",
        "Bange",
        "Banjo",
        "Bantu",
        "Baren",
        "Bares",
        "Barke",
        "Baron",
        "Basar",
        "Basel",
        "Basen",
        "Basic",
        "Batik",
        "Bator",
        "Bauch",
        "Bauer",
        "Baume",
        "Bazar",
        "Beben",
        "Bebop",
        "Beere",
        "Beile",
        "Beine",
        "Beleg",
        "Berge",
        "Berta",
        "Beruf",
        "Besen",
        "Betel",
        "Beton",
        "Betty",
        "Beule",
        "Beute",
        "Bevor",
        "Bezug",
        "Biber",
        "Biene",
        "Biere",
        "Billy",
        "Birne",
        "Bisse",
        "Bitte",
        "Biwak",
        "Blase",
        "Blatt",
        "Blech",
        "Blick",
        "Blitz",
        "Block",
        "Blois",
        "Bluff",
        "Blume",
        "Bluts",
        "Bnsai",
        "Bogen",
        "Bohle",
        "Bombe",
        "Bongo",
        "Bonns",
        "Bonus",
        "Bonze",
        "Boote",
        "Boots",
        "Borke",
        "Borte",
        "Bosch",
        "Bosse",
        "Boten",
        "Botha",
        "Botin",
        "Bowle",
        "Boyen",
        "Brady",
        "Braue",
        "Braus",
        "Braut",
        "Breie",
        "Breis",
        "Brest",
        "Brett",
        "Brief",
        "Brise",
        "Brite",
        "Bruch",
        "Brust",
        "Buben",
        "Buche",
        "Buchs",
        "Buden",
        "Buett",
        "Buges",
        "Bulle",
        "Bunde",
        "Burda",
        "Burma",
        "Busch",
        "Busse",
        "Busse",
        "C-Dur",
        "COBOL",
        "Cadiz",
        "Camus",
        "Canon",
        "Capua",
        "Carle",
        "Carlo",
        "Carol",
        "Cathy",
        "Celle",
        "Cents",
        "Chaos",
        "Chase",
        "Chefs",
        "Chile",
        "China",
        "Chips",
        "Choco",
        "Choke",
        "Chors",
        "Civil",
        "Clone",
        "Clubs",
        "Cluny",
        "Coats",
        "Cobol",
        "Codes",
        "Codex",
        "Corps",
        "Coupe",
        "Coups",
        "Crash",
        "Creme",
        "Crews",
        "Croix",
        "Curie",
        "D-Zug",
        "Dabei",
        "Dachs",
        "Daene",
        "Dakar",
        "Damen",
        "Damit",
        "Dandy",
        "Dante",
        "Daran",
        "Darin",
        "Datei",
        "Daten",
        "Datex",
        "Datum",
        "Dauer",
        "Daune",
        "David",
        "Davon",
        "Davor",
        "Davos",
        "Deich",
        "Dekan",
        "Dekor",
        "Delle",
        "Demut",
        "Depot",
        "Deren",
        "Devon",
        "Dhabi",
        "Diaet",
        "Diebe",
        "Diebs",
        "Diele",
        "Diese",
        "Dills",
        "Dinar",
        "Dirne",
        "Disco",
        "Discs",
        "Diwan",
        "Docks",
        "Dogma",
        "Dohle",
        "Dolly",
        "Domes",
        "Doris",
        "Dorne",
        "Dorns",
        "Dosen",
        "Dosis",
        "Dover",
        "Draht",
        "Drama",
        "Dress",
        "Drift",
        "Drink",
        "Droge",
        "Droht",
        "Druck",
        "Duell",
        "Duene",
        "Duese",
        "Duett",
        "Dumme",
        "Dunst",
        "Durch",
        "Durst",
        "Dusel",
        "Ebben",
        "Ebene",
        "Ebert",
        "Echos",
        "Echte",
        "Ecken",
        "Edeka",
        "Edens",
        "Efeus",
        "Egeln",
        "Egels",
        "Ehren",
        "Eiben",
        "Eiche",
        "Eiden",
        "Eides",
        "Eiern",
        "Eifel",
        "Eifer",
        "Eigen",
        "Eimer",
        "Einen",
        "Einer",
        "Eines",
        "Eisen",
        "Eises",
        "Eiter",
        "Ekels",
        "Eklat",
        "Ekzem",
        "Elans",
        "Elend",
        "Elias",
        "Elite",
        "Email",
        "Endes",
        "Endet",
        "Engel",
        "Enkel",
        "Enzym",
        "Epcot",
        "Erbin",
        "Erbse",
        "Erich",
        "Erika",
        "Erlen",
        "Ernst",
        "Ernte",
        "Erzen",
        "Erzes",
        "Esche",
        "Eseln",
        "Essig",
        "Etage",
        "Etats",
        "Ethik",
        "Ethos",
        "Etwas",
        "Eulen",
        "Euler",
        "Exile",
        "Exils",
        "Extra",
        "Fabel",
        "Faden",
        "Fahne",
        "Falke",
        "Falle",
        "Fango",
        "Fangs",
        "Farbe",
        "Faruk",
        "Fasan",
        "Fatum",
        "Fauna",
        "Faust",
        "Faxen",
        "Fazit",
        "Feder",
        "Fehde",
        "Fehlt",
        "Feier",
        "Feind",
        "Felde",
        "Felge",
        "Felix",
        "Fells",
        "Ferne",
        "Ferse",
        "Feste",
        "Fette",
        "Feuer",
        "Fiako",
        "Fiber",
        "Files",
        "Filet",
        "Filme",
        "Films",
        "Final",
        "Finne",
        "Finte",
        "Firma",
        "First",
        "Fisch",
        "Fjord",
        "Flair",
        "Flash",
        "Fleck",
        "Flohs",
        "Flora",
        "Fluge",
        "Flugs",
        "Fluor",
        "Flurs",
        "Fluss",
        "Foens",
        "Folgt",
        "Folie",
        "Foren",
        "Forst",
        "Forum",
        "Fotos",
        "Fouls",
        "Foyer",
        "Frack",
        "Frage",
        "Fragt",
        "Franc",
        "Frank",
        "Freud",
        "Frist",
        "Frust",
        "Fuder",
        "Fuenf",
        "Fugen",
        "Fuhre",
        "Funde",
        "Funks",
        "Furie",
        "Fusel",
        "Fusse",
        "Gabun",
        "Gagen",
        "Galas",
        "Galle",
        "Ganze",
        "Garbe",
        "Garbo",
        "Garne",
        "Garns",
        "Gasen",
        "Gases",
        "Gatte",
        "Gauda",
        "Gaudi",
        "Geben",
        "Geber",
        "Gebet",
        "Gehen",
        "Geier",
        "Geiss",
        "Geist",
        "Gelde",
        "Genau",
        "Genen",
        "Genie",
        "Genre",
        "Genua",
        "Georg",
        "Gerte",
        "Geste",
        "Getue",
        "Giant",
        "Gicht",
        "Gifte",
        "Gilde",
        "Girls",
        "Gizeh",
        "Glanz",
        "Gleis",
        "Glied",
        "Gnade",
        "Gnome",
        "Goere",
        "Golda",
        "Golde",
        "Golfs",
        "Gorby",
        "Gorki",
        "Gosse",
        "Gotts",
        "Gouda",
        "Grace",
        "Gramm",
        "Grams",
        "Grate",
        "Gratz",
        "Graue",
        "Greis",
        "Grete",
        "Griff",
        "Grips",
        "Gruft",
        "Grund",
        "Gucci",
        "Guete",
        "Guido",
        "Gulag",
        "Gummi",
        "Gunst",
        "Gurke",
        "Gusto",
        "Guten",
        "Guter",
        "Haars",
        "Hades",
        "Haelt",
        "Hafen",
        "Hafer",
        "Hagel",
        "Hagen",
        "Hahns",
        "Haien",
        "Haifa",
        "Haken",
        "Halle",
        "Hallo",
        "Halme",
        "Halms",
        "Hanne",
        "Hanoi",
        "Hansa",
        "Hansi",
        "Harfe",
        "Harns",
        "Harro",
        "Harte",
        "Hasen",
        "Haube",
        "Hauch",
        "Hauer",
        "Hauff",
        "Haupt",
        "Hause",
        "Hawai",
        "Heber",
        "Hedda",
        "Heers",
        "Hefts",
        "Hegel",
        "Heike",
        "Heine",
        "Heino",
        "Heinz",
        "Heiss",
        "Helds",
        "Helen",
        "Helme",
        "Helms",
        "Hemds",
        "Henne",
        "Henry",
        "Herde",
        "Heros",
        "Herrs",
        "Hertz",
        "Hesse",
        "Heuss",
        "Heute",
        "Hexen",
        "Hexer",
        "Hilde",
        "Hilfe",
        "Hilft",
        "Hinzu",
        "Hippy",
        "Hirne",
        "Hirns",
        "Hirse",
        "Hitze",
        "Hobel",
        "Hochs",
        "Hoehe",
        "Hofes",
        "Hoher",
        "Holde",
        "Homer",
        "Honda",
        "Horns",
        "Hotel",
        "Huber",
        "Huene",
        "Hufen",
        "Huhns",
        "Humus",
        "Hunde",
        "Husar",
        "Husum",
        "Hutes",
        "Hymne",
        "Ideen",
        "Idiot",
        "Idole",
        "Idols",
        "Idyll",
        "Igeln",
        "Igels",
        "Ihnen",
        "Ihrem",
        "Ihren",
        "Ihrer",
        "Ikone",
        "Image",
        "Indem",
        "Inder",
        "Index",
        "Indiz",
        "Indus",
        "Insel",
        "Ionen",
        "Irish",
        "Isaac",
        "Isaak",
        "Islam",
        "Jacht",
        "Jacke",
        "Jacob",
        "Jaffa",
        "Jahre",
        "Jahrs",
        "Jahwe",
        "Jalta",
        "James",
        "Japan",
        "Jause",
        "Jedem",
        "Jeden",
        "Jeder",
        "Jedes",
        "Jemen",
        "Jesus",
        "Jetzt",
        "Jogis",
        "Joker",
        "Jones",
        "Josef",
        "Jubel",
        "Judas",
        "Juden",
        "Judos",
        "Julia",
        "Julis",
        "Junge",
        "Junis",
        "Jupes",
        "Juras",
        "Jurys",
        "KPdSU",
        "Kaaba",
        "Kabul",
        "Kader",
        "Kaese",
        "Kaffs",
        "Kafka",
        "Kahns",
        "Kairo",
        "Kakao",
        "Kalif",
        "Kalks",
        "Kalte",
        "Kamin",
        "Kamms",
        "Kampf",
        "Kanal",
        "Kanne",
        "Kanon",
        "Kante",
        "Kappe",
        "Karat",
        "Karin",
        "Karos",
        "Karre",
        "Karte",
        "Kasko",
        "Kasse",
        "Kasus",
        "Kater",
        "Katia",
        "Kaufs",
        "Kegel",
        "Kehle",
        "Keile",
        "Keils",
        "Keims",
        "Keine",
        "Kekse",
        "Kelch",
        "Kelle",
        "Kenia",
        "Kerbe",
        "Kerle",
        "Kerls",
        "Kerne",
        "Kerns",
        "Kette",
        "Keule",
        "Khmer",
        "Kiele",
        "Kilos",
        "Kings",
        "Kinns",
        "Kinos",
        "Kiosk",
        "Kitts",
        "Klage",
        "Klang",
        "Klees",
        "Kleie",
        "Klein",
        "Klett",
        "Kleve",
        "Klick",
        "Klima",
        "Kloss",
        "Klotz",
        "Klubs",
        "Knabe",
        "Knall",
        "Knast",
        "Knauf",
        "Knaur",
        "Knete",
        "Knick",
        "Kniee",
        "Knien",
        "Knies",
        "Kniff",
        "Knopf",
        "Kobra",
        "Kochs",
        "Koeln",
        "Kohle",
        "Kohls",
        "Kojen",
        "Kokon",
        "Kolik",
        "Kombi",
        "Komet",
        "Komik",
        "Komma",
        "Kommt",
        "Kongo",
        "Konto",
        "Kopfe",
        "Kopie",
        "Koran",
        "Korea",
        "Korns",
        "Korso",
        "Krach",
        "Kraft",
        "Kraut",
        "Krebs",
        "Kreis",
        "Kreme",
        "Kreml",
        "Kreta",
        "Kreuz",
        "Krieg",
        "Krimi",
        "Krise",
        "Krume",
        "Kuala",
        "Kubas",
        "Kubus",
        "Kuehe",
        "Kugel",
        "Kupee",
        "Kupon",
        "Kuppe",
        "Kurde",
        "Kurie",
        "Kurse",
        "Kurze",
        "Kyoto",
        "Label",
        "Labor",
        "Lachs",
        "Lacke",
        "Laden",
        "Laerm",
        "Lagen",
        "Lager",
        "Lampe",
        "Lande",
        "Lange",
        "Lanka",
        "Larve",
        "Lasso",
        "Latex",
        "Lauch",
        "Laufe",
        "Laufs",
        "Lauge",
        "Laune",
        "Leben",
        "Lecks",
        "Leere",
        "Legen",
        "Lehre",
        "Leibe",
        "Leibs",
        "Leica",
        "Leier",
        "Leine",
        "Leise",
        "Lenin",
        "Lenze",
        "Leone",
        "Lepra",
        "Lesen",
        "Leser",
        "Lette",
        "Leute",
        "Liane",
        "Licht",
        "Lides",
        "Liebe",
        "Liegt",
        "Lifts",
        "Lilie",
        "Lille",
        "Lilly",
        "Limit",
        "Linie",
        "Linke",
        "Links",
        "Linse",
        "Lippe",
        "Liste",
        "Liter",
        "Lloyd",
        "Lobby",
        "Lobes",
        "Loess",
        "Loewe",
        "Logik",
        "Logis",
        "Lohnt",
        "Loire",
        "Lords",
        "Loren",
        "Lotte",
        "Lotto",
        "Lotus",
        "Louis",
        "Luchs",
        "Luder",
        "Luege",
        "Lunas",
        "Lunch",
        "Lunge",
        "Lunte",
        "Luxus",
        "Lyder",
        "Lynch",
        "Lyrik",
        "MByte",
        "MSDOS",
        "Mache",
        "Macke",
        "Maden",
        "Maeni",
        "Maerz",
        "Mafia",
        "Magen",
        "Magie",
        "Magna",
        "Mainz",
        "Makel",
        "Maler",
        "Malta",
        "Malus",
        "Mamas",
        "Mamba",
        "Manie",
        "Manko",
        "Maori",
        "Mappe",
        "Marat",
        "Marge",
        "Marie",
        "Markt",
        "Marsa",
        "Masel",
        "Maske",
        "Masse",
        "Mater",
        "Mauer",
        "Maxim",
        "Mazda",
        "Mbyte",
        "Meere",
        "Meers",
        "Mehls",
        "Meier",
        "Meile",
        "Meint",
        "Meise",
        "Meist",
        "Memel",
        "Mensa",
        "Menue",
        "Meran",
        "Merck",
        "Messe",
        "Meter",
        "Meute",
        "Midas",
        "Mieke",
        "Mikro",
        "Milan",
        "Milch",
        "Miliz",
        "Mimen",
        "Mimik",
        "Minis",
        "Minne",
        "Minni",
        "Minus",
        "Mixer",
        "MoOet",
        "Modem",
        "Modul",
        "Moege",
        "Moewe",
        "Mokka",
        "Molen",
        "Molle",
        "Molly",
        "Monat",
        "Monde",
        "Monte",
        "Moral",
        "Moron",
        "Mosel",
        "Moser",
        "Moses",
        "Motel",
        "Motiv",
        "Motor",
        "Motte",
        "Motus",
        "Muehe",
        "Muell",
        "Muenz",
        "Mulde",
        "Multi",
        "Mumie",
        "Murks",
        "Musik",
        "Musse",
        "Mutes",
        "Mutti",
        "Nabel",
        "Nacht",
        "Nadel",
        "Naehe",
        "Nagel",
        "Nahen",
        "Naive",
        "Namur",
        "Nasen",
        "Nazis",
        "Nebel",
        "Neben",
        "Neffe",
        "Neger",
        "Negev",
        "Negro",
        "Nehme",
        "Nehru",
        "Neige",
        "Neons",
        "Nepal",
        "Nervs",
        "Nerze",
        "Nette",
        "Netto",
        "Netze",
        "Neuen",
        "Neues",
        "Neuss",
        "Nicht",
        "Niele",
        "Niere",
        "Niete",
        "Niger",
        "Nikki",
        "Nikko",
        "Nikon",
        "Nizza",
        "Noete",
        "Nonne",
        "Notar",
        "Noten",
        "Notiz",
        "Notre",
        "Novum",
        "Nudel",
        "Nylon",
        "ODECA",
        "Oasis",
        "Obers",
        "Ochse",
        "Odium",
        "Oefen",
        "Oesen",
        "Oheim",
        "Ohren",
        "Ohres",
        "Oktav",
        "Olymp",
        "Omaha",
        "Omega",
        "Opern",
        "Opfer",
        "Opium",
        "Orden",
        "Order",
        "Orgel",
        "Orgie",
        "Orion",
        "Orkan",
        "Orten",
        "Ortes",
        "Oscar",
        "Oskar",
        "Osten",
        "Otter",
        "Ozean",
        "PRINT",
        "Paare",
        "Pablo",
        "Paket",
        "Pakte",
        "Pakts",
        "Papas",
        "Pappe",
        "Paris",
        "Parks",
        "Parts",
        "Party",
        "Paste",
        "Paten",
        "Pauke",
        "Pavia",
        "Pechs",
        "Pedal",
        "Pedro",
        "Pegel",
        "Peggy",
        "Pence",
        "Pepsi",
        "Perle",
        "Perus",
        "Peter",
        "Petra",
        "Petri",
        "Petro",
        "Pfade",
        "Pfalz",
        "Pfand",
        "Pfaue",
        "Pfaus",
        "Pfeil",
        "Pferd",
        "Pflug",
        "Pfote",
        "Pfuhl",
        "Phase",
        "Phnom",
        "Photo",
        "Pille",
        "Pilot",
        "Pilze",
        "Pinie",
        "Piper",
        "Pippi",
        "Pirat",
        "Piste",
        "Pixel",
        "Plato",
        "Plaza",
        "Pluto",
        "Pneus",
        "Pokal",
        "Poker",
        "Polin",
        "Polis",
        "Polit",
        "Polle",
        "Pomps",
        "Ponte",
        "Ponys",
        "Poren",
        "Porno",
        "Porto",
        "Posen",
        "Power",
        "Prado",
        "Prags",
        "Preis",
        "Prosa",
        "Prunk",
        "Puder",
        "Puett",
        "Pulle",
        "Pulli",
        "Pulte",
        "Pumps",
        "Punkt",
        "Puppe",
        "Puten",
        "Puzzi",
        "Qualm",
        "Quart",
        "Queen",
        "Quere",
        "RSFSR",
        "Rabat",
        "Rache",
        "Radau",
        "Radio",
        "Radon",
        "Raete",
        "Rahms",
        "Rally",
        "Rambo",
        "Rampe",
        "Rande",
        "Rasse",
        "Rasta",
        "Raten",
        "Rates",
        "Ratte",
        "Rauch",
        "Raudi",
        "Raume",
        "Raums",
        "Reale",
        "Realo",
        "Reben",
        "Recht",
        "Regal",
        "Regel",
        "Regen",
        "Reger",
        "Regie",
        "Reihe",
        "Reims",
        "Reise",
        "Rente",
        "Reste",
        "Rests",
        "Rhode",
        "Rhone",
        "Ricke",
        "Riege",
        "Rinds",
        "Rings",
        "Rippe",
        "Risse",
        "Riten",
        "Rival",
        "Robbe",
        "Robin",
        "Roebi",
        "Roehm",
        "Roete",
        "Roger",
        "Rohre",
        "Rolex",
        "Rolle",
        "Rolls",
        "Roman",
        "Rosen",
        "Rotor",
        "Royce",
        "Rubik",
        "Ruder",
        "Rufen",
        "Ruhms",
        "Ruins",
        "Rumpf",
        "Runde",
        "Russe",
        "SEATO",
        "SHELL",
        "SWAPO",
        "Saals",
        "Saats",
        "Sadat",
        "Saele",
        "Saeue",
        "Safte",
        "Safts",
        "Sagen",
        "Sagte",
        "Saite",
        "Salat",
        "Saldo",
        "Salem",
        "Salon",
        "Salto",
        "Salut",
        "Salve",
        "Salze",
        "Samen",
        "Samts",
        "Sande",
        "Sands",
        "Santa",
        "Sanyo",
        "Sargs",
        "Satan",
        "Satin",
        "Satze",
        "Sauce",
        "Saudi",
        "Sauna",
        "Scala",
        "Schaf",
        "Schah",
        "Schar",
        "Schau",
        "Schub",
        "Schuh",
        "Scout",
        "Seele",
        "Segen",
        "Sehen",
        "Seher",
        "Seich",
        "Seide",
        "Seife",
        "Seiko",
        "Seils",
        "Seims",
        "Seins",
        "Seite",
        "Sekte",
        "Sekts",
        "Semit",
        "Senat",
        "Senfs",
        "Seoul",
        "Serbe",
        "Seren",
        "Serie",
        "Serif",
        "Sesam",
        "Setzt",
        "Sexes",
        "Sexus",
        "Shell",
        "Shows",
        "Sicht",
        "Siegs",
        "Siels",
        "Sigma",
        "Silbe",
        "Simon",
        "Sinai",
        "Sinus",
        "Sippe",
        "Sirup",
        "Sitte",
        "Sitze",
        "Skala",
        "Skats",
        "Skier",
        "Slave",
        "Slums",
        "Snobs",
        "Socke",
        "Sodom",
        "Sofas",
        "Sofia",
        "Sogar",
        "Sohne",
        "Sohns",
        "Solde",
        "Solds",
        "Solon",
        "Sonne",
        "Sonst",
        "Sorge",
        "Sorte",
        "Sosse",
        "Sound",
        "Sowie",
        "Spalt",
        "Spann",
        "Spans",
        "Spant",
        "Spass",
        "Speck",
        "Speer",
        "Spiel",
        "Spion",
        "Spore",
        "Sporn",
        "Sport",
        "Spreu",
        "Sprit",
        "Spuks",
        "Staat",
        "Stabs",
        "Stadt",
        "Stahl",
        "Stake",
        "Stall",
        "Stamm",
        "Stand",
        "Stare",
        "Stars",
        "Statt",
        "Staub",
        "Staus",
        "Steak",
        "Stege",
        "Stegs",
        "Steht",
        "Steve",
        "Stich",
        "Stiel",
        "Stier",
        "Stift",
        "Stile",
        "Stils",
        "Stirn",
        "Stock",
        "Stola",
        "Stolz",
        "Stopp",
        "Story",
        "Stoss",
        "Stout",
        "Stroh",
        "Stube",
        "Stuck",
        "Stufe",
        "Stunk",
        "Sturm",
        "Stuss",
        "Stute",
        "Suche",
        "Sudan",
        "Suite",
        "Sumpf",
        "Super",
        "Suppe",
        "Sushi",
        "Swing",
        "Syrer",
        "TIMES",
        "Tabak",
        "Tadel",
        "Tafel",
        "Tages",
        "Taiga",
        "Takel",
        "Takte",
        "Takts",
        "Taler",
        "Tales",
        "Talgs",
        "Talks",
        "Talon",
        "Tands",
        "Tange",
        "Tango",
        "Tangs",
        "Tanks",
        "Tante",
        "Tapet",
        "Taras",
        "Tasse",
        "Taste",
        "Taten",
        "Tatze",
        "Taube",
        "Taxen",
        "Taxis",
        "Teams",
        "Teers",
        "Teich",
        "Teigs",
        "Teile",
        "Teils",
        "Teint",
        "Telex",
        "Tempo",
        "Tenne",
        "Tenor",
        "Terze",
        "Tests",
        "Texas",
        "Theke",
        "Thema",
        "Therm",
        "These",
        "Tibet",
        "Ticks",
        "Tiere",
        "Tiers",
        "Times",
        "Tinte",
        "Tiran",
        "Tirol",
        "Titan",
        "Titel",
        "Tobak",
        "Todes",
        "Tokio",
        "Tolle",
        "Tones",
        "Toren",
        "Torfs",
        "Torso",
        "Totos",
        "Tower",
        "Trabi",
        "Trakt",
        "Trane",
        "Trans",
        "Traum",
        "Treff",
        "Trend",
        "Treue",
        "Trick",
        "Trieb",
        "Trios",
        "Tritt",
        "Troja",
        "Trost",
        "Trotz",
        "Truck",
        "Truhe",
        "Trunk",
        "Trust",
        "Tuben",
        "Tuell",
        "Tuere",
        "Tuete",
        "Tuffs",
        "Tulpe",
        "Tumor",
        "Tunis",
        "Turbo",
        "Turme",
        "Turms",
        "Tusch",
        "Tutor",
        "Typen",
        "Typus",
        "UNITA",
        "UdSSR",
        "Uebel",
        "Ueber",
        "Ufers",
        "Uhren",
        "Ulmen",
        "Umweg",
        "Umzug",
        "Ungar",
        "Union",
        "Unken",
        "Unmut",
        "Unruh",
        "Unser",
        "Untat",
        "Unter",
        "Urans",
        "Urins",
        "Vater",
        "Vatis",
        "Vegas",
        "Venen",
        "Venus",
        "Verdi",
        "Verse",
        "Vetos",
        "Veuve",
        "Video",
        "Viele",
        "Villa",
        "Viola",
        "Viper",
        "Viren",
        "Visum",
        "Vokal",
        "Volke",
        "Volks",
        "Volle",
        "Vorab",
        "Votum",
        "WITCH",
        "Waben",
        "Wachs",
        "Waffe",
        "Wagen",
        "Wahns",
        "Wahre",
        "Waise",
        "Walde",
        "Walen",
        "Wales",
        "Walls",
        "Wange",
        "Wanne",
        "Wanst",
        "Wanze",
        "Waren",
        "Warze",
        "Watte",
        "Watts",
        "Weber",
        "Wedel",
        "Wegen",
        "Wehen",
        "Weibe",
        "Weich",
        "Weide",
        "Weile",
        "Weins",
        "Weise",
        "Weiss",
        "Welpe",
        "Wende",
        "Werbe",
        "Werft",
        "Werks",
        "Werte",
        "Wesen",
        "Weser",
        "Weste",
        "Whigs",
        "Wicke",
        "Wiege",
        "Wieso",
        "Wille",
        "Willy",
        "Winks",
        "Wippe",
        "Wirte",
        "Wirth",
        "Witwe",
        "Witze",
        "Wobei",
        "Woche",
        "Wohin",
        "Wohle",
        "Wolke",
        "Wolle",
        "Womit",
        "Wonne",
        "Woran",
        "World",
        "Worms",
        "Worte",
        "Worts",
        "Wotan",
        "Wovon",
        "Wovor",
        "Wucht",
        "Wunde",
        "Wurde",
        "Wurms",
        "Wurst",
        "XENIX",
        "XEROX",
        "Xerox",
        "Yacht",
        "Zahle",
        "Zahns",
        "Zange",
        "Zaren",
        "Zarin",
        "Zebra",
        "Zeche",
        "Zehen",
        "Zeigt",
        "Zeile",
        "Zelle",
        "Zelte",
        "Zeuge",
        "Zeugs",
        "Zicke",
        "Ziege",
        "Zieht",
        "Ziele",
        "Zimts",
        "Zinne",
        "Zinns",
        "Zinse",
        "Zitat",
        "Zitze",
        "Zofen",
        "Zorns",
        "Zorro",
        "Zoten",
        "Zuber",
        "Zucht",
        "Zuges",
        "Zumal",
        "Zunft",
        "Zunge",
        "Zuppa",
        "Zuruf",
        "Zuzug",
        "Zweig",
        "Zwick",
        "Zwirn",
        "Zwist",
        "Zyste",
        "abgab",
        "abkam",
        "abtun",
        "abzgl",
        "abzog",
        "achte",
        "adeln",
        "adelt",
        "adieu",
        "adlig",
        "aefft",
        "aetzt",
        "affig",
        "agile",
        "ahnde",
        "ahnen",
        "ahnte",
        "aktiv",
        "akute",
        "allen",
        "aller",
        "alles",
        "allwo",
        "almen",
        "altem",
        "alten",
        "alter",
        "altes",
        "amigo",
        "amour",
        "amtet",
        "anbei",
        "ander",
        "angab",
        "angle",
        "anhob",
        "ankam",
        "ansah",
        "antat",
        "antik",
        "antut",
        "anzog",
        "apart",
        "argen",
        "arger",
        "arges",
        "arher",
        "armem",
        "armen",
        "armes",
        "assen",
        "backe",
        "baden",
        "badet",
        "bahre",
        "ballt",
        "banal",
        "bange",
        "bangt",
        "banne",
        "bannt",
        "barer",
        "barst",
        "batet",
        "bauen",
        "baust",
        "baute",
        "beben",
        "bebte",
        "behob",
        "beide",
        "beige",
        "belle",
        "belog",
        "besah",
        "beste",
        "beten",
        "betet",
        "beuge",
        "beugt",
        "bewog",
        "bezog",
        "biege",
        "biegt",
        "biete",
        "bilde",
        "binde",
        "birgt",
        "bisst",
        "bitte",
        "blass",
        "blaue",
        "bleib",
        "blick",
        "blieb",
        "blies",
        "blind",
        "bloed",
        "blond",
        "blute",
        "boeig",
        "boese",
        "boget",
        "bogst",
        "borge",
        "borgt",
        "botet",
        "boxen",
        "boxte",
        "brate",
        "brave",
        "breit",
        "bring",
        "brown",
        "buche",
        "bucht",
        "bumst",
        "bunte",
        "carte",
        "chick",
        "circa",
        "class",
        "comic",
        "crack",
        "dBase",
        "daher",
        "dahin",
        "damit",
        "dampf",
        "dance",
        "danke",
        "dankt",
        "daran",
        "darum",
        "daure",
        "davon",
        "dehne",
        "dehnt",
        "denen",
        "denke",
        "derbe",
        "deren",
        "derer",
        "desto",
        "deute",
        "devot",
        "dicht",
        "diene",
        "dient",
        "doese",
        "doest",
        "doofe",
        "drang",
        "drauf",
        "drein",
        "dritt",
        "droht",
        "dubio",
        "ducke",
        "duckt",
        "duenn",
        "dufte",
        "dumpf",
        "durch",
        "ebnen",
        "echte",
        "eckig",
        "edlen",
        "edler",
        "edles",
        "ehrst",
        "ehrte",
        "eifre",
        "eigne",
        "eilig",
        "eilst",
        "einen",
        "einer",
        "eines",
        "einig",
        "einst",
        "eisig",
        "eitel",
        "elfte",
        "empor",
        "emsig",
        "enden",
        "endet",
        "engem",
        "engen",
        "enger",
        "enges",
        "enorm",
        "erbot",
        "erbst",
        "erbte",
        "ergab",
        "erlag",
        "ernte",
        "erzog",
        "etwas",
        "eurem",
        "euren",
        "eures",
        "ewige",
        "exakt",
        "fache",
        "facto",
        "fadem",
        "faden",
        "fader",
        "fades",
        "fahle",
        "fahre",
        "fahrt",
        "falbe",
        "falls",
        "falte",
        "famos",
        "fange",
        "fasse",
        "fasst",
        "faste",
        "fatal",
        "faule",
        "fegen",
        "fegst",
        "fegte",
        "feige",
        "feilt",
        "feire",
        "fesch",
        "feure",
        "fidel",
        "fides",
        "fiele",
        "fielt",
        "filmt",
        "finde",
        "fixen",
        "fixer",
        "fixte",
        "flaue",
        "flehe",
        "fleht",
        "flink",
        "flitz",
        "flohe",
        "floss",
        "flott",
        "fluch",
        "flugs",
        "fluid",
        "focht",
        "foppe",
        "foppt",
        "forme",
        "formt",
        "frage",
        "fragt",
        "frass",
        "frech",
        "freie",
        "fremd",
        "freue",
        "frohe",
        "fromm",
        "frueh",
        "fuegt",
        "fuers",
        "funke",
        "funkt",
        "fuzzy",
        "gaben",
        "gaebe",
        "gaert",
        "gaffe",
        "gafft",
        "garni",
        "geben",
        "gegen",
        "gehen",
        "gehst",
        "geile",
        "geize",
        "geizt",
        "gelbe",
        "gelle",
        "gellt",
        "gelte",
        "genas",
        "genau",
        "genug",
        "gerne",
        "getan",
        "gibst",
        "ginge",
        "glatt",
        "glaub",
        "glich",
        "glitt",
        "grabe",
        "grabt",
        "grase",
        "grast",
        "graue",
        "grell",
        "griff",
        "gross",
        "gruen",
        "guckt",
        "gutem",
        "guter",
        "gutes",
        "haben",
        "hacke",
        "hackt",
        "haelt",
        "hafte",
        "hager",
        "hakte",
        "halbe",
        "hallt",
        "hange",
        "happy",
        "harke",
        "hasch",
        "hasse",
        "hasst",
        "haste",
        "hat's",
        "hauen",
        "haust",
        "haute",
        "heben",
        "hebst",
        "hefte",
        "hegen",
        "hegte",
        "heile",
        "heiss",
        "heize",
        "heizt",
        "helfe",
        "helft",
        "helle",
        "hello",
        "hemmt",
        "herab",
        "heran",
        "herbe",
        "herum",
        "herzu",
        "hetzt",
        "heuer",
        "heule",
        "heult",
        "heute",
        "hielt",
        "hiess",
        "hilft",
        "hinab",
        "hinan",
        "hinge",
        "hinkt",
        "hinzu",
        "hirte",
        "hisst",
        "hobet",
        "hoble",
        "hobst",
        "hocke",
        "hockt",
        "hoert",
        "hoffe",
        "hofft",
        "hohen",
        "hohes",
        "holen",
        "holst",
        "holte",
        "human",
        "hupen",
        "hupst",
        "hupte",
        "huren",
        "hurra",
        "ideal",
        "ihnen",
        "ihren",
        "ihrer",
        "immun",
        "impfe",
        "impft",
        "indes",
        "inner",
        "innig",
        "intim",
        "irren",
        "irrer",
        "irres",
        "irrig",
        "irrst",
        "irrte",
        "jagst",
        "jagte",
        "jedem",
        "jeden",
        "jeder",
        "jedes",
        "jenen",
        "jener",
        "jenes",
        "jetzt",
        "juble",
        "juhee",
        "juhei",
        "kByte",
        "kahle",
        "kamst",
        "kanns",
        "karge",
        "kaute",
        "kegle",
        "kehre",
        "kehrt",
        "keife",
        "keift",
        "keime",
        "keimt",
        "keine",
        "keins",
        "kenne",
        "kennt",
        "kerbe",
        "kerbt",
        "kesse",
        "kicke",
        "kille",
        "killt",
        "kippe",
        "kippt",
        "kitte",
        "klage",
        "klagt",
        "klaue",
        "klaut",
        "klipp",
        "kluge",
        "knapp",
        "knete",
        "knien",
        "koche",
        "komme",
        "kommt",
        "koste",
        "kraft",
        "krame",
        "krank",
        "kraus",
        "kriti",
        "kroch",
        "krumm",
        "kuehl",
        "kuren",
        "kurvt",
        "kurze",
        "labil",
        "labst",
        "labte",
        "lacht",
        "ladet",
        "laege",
        "lagen",
        "lagst",
        "lange",
        "lasen",
        "lasst",
        "laste",
        "laude",
        "lauem",
        "lauer",
        "lause",
        "laust",
        "laute",
        "least",
        "leben",
        "lebst",
        "lecke",
        "leckt",
        "ledig",
        "leert",
        "legal",
        "leger",
        "legte",
        "lehne",
        "lehnt",
        "leide",
        "leiht",
        "leime",
        "leimt",
        "leite",
        "lerne",
        "lernt",
        "liebe",
        "liebt",
        "liefe",
        "lieft",
        "liege",
        "liegt",
        "lieht",
        "liess",
        "liest",
        "lifte",
        "limes",
        "loben",
        "lobte",
        "loche",
        "locht",
        "locke",
        "lockt",
        "loese",
        "loest",
        "logen",
        "lokal",
        "losen",
        "loste",
        "lotse",
        "luden",
        "luege",
        "luegt",
        "mache",
        "macht",
        "maehe",
        "maeht",
        "magst",
        "mahlt",
        "malst",
        "malte",
        "manch",
        "mault",
        "media",
        "mehre",
        "mehrt",
        "meide",
        "meine",
        "meist",
        "melde",
        "melke",
        "melkt",
        "merci",
        "merke",
        "merkt",
        "miese",
        "miete",
        "milde",
        "minim",
        "minnt",
        "misse",
        "misst",
        "mixed",
        "mixte",
        "mobil",
        "mogle",
        "moste",
        "motzt",
        "muede",
        "mueht",
        "munde",
        "mutig",
        "naehe",
        "naeht",
        "nagen",
        "nagst",
        "nagte",
        "nahen",
        "nahes",
        "nahmt",
        "nahst",
        "nahte",
        "narrt",
        "nebst",
        "necke",
        "neckt",
        "nehmt",
        "neigt",
        "nenne",
        "nennt",
        "nervt",
        "neuen",
        "neuer",
        "neues",
        "nicht",
        "nickt",
        "niese",
        "nippe",
        "nippt",
        "niste",
        "noble",
        "norme",
        "nutzt",
        "obern",
        "obige",
        "oblag",
        "ocker",
        "oeden",
        "oeder",
        "oelen",
        "oelig",
        "oelst",
        "oelte",
        "offen",
        "opake",
        "ordne",
        "orten",
        "packe",
        "paffe",
        "pafft",
        "parke",
        "passe",
        "peilt",
        "pfade",
        "pfiff",
        "piano",
        "pisse",
        "plage",
        "plagt",
        "plane",
        "plant",
        "platt",
        "plump",
        "pocht",
        "polar",
        "prall",
        "preis",
        "pries",
        "prima",
        "print",
        "probt",
        "prost",
        "pumpe",
        "pumpt",
        "purem",
        "puren",
        "pures",
        "puste",
        "putze",
        "quasi",
        "quell",
        "quick",
        "raffe",
        "rafft",
        "ragst",
        "ragte",
        "ramme",
        "rammt",
        "rangt",
        "rarem",
        "rarer",
        "rares",
        "rasch",
        "ratet",
        "ratio",
        "raubt",
        "rauhe",
        "raunt",
        "recht",
        "reckt",
        "redet",
        "reell",
        "regem",
        "regen",
        "reges",
        "regte",
        "reibe",
        "reibt",
        "reife",
        "reift",
        "reiht",
        "reime",
        "reimt",
        "reist",
        "reite",
        "remis",
        "renne",
        "rette",
        "rieft",
        "ringe",
        "ringt",
        "rinnt",
        "ritte",
        "ritze",
        "ritzt",
        "robbt",
        "rocht",
        "rohem",
        "rohen",
        "rohes",
        "rollt",
        "roste",
        "rotem",
        "roten",
        "roter",
        "rotzt",
        "rouge",
        "rufst",
        "ruhen",
        "ruhig",
        "ruhst",
        "ruhte",
        "rupfe",
        "rupft",
        "saeen",
        "saege",
        "saegt",
        "saehe",
        "saest",
        "saete",
        "sag's",
        "sagen",
        "sahen",
        "sahet",
        "sahst",
        "sanft",
        "sankt",
        "sauen",
        "sauer",
        "saufe",
        "sauft",
        "saure",
        "sause",
        "saust",
        "schal",
        "scheu",
        "schob",
        "schon",
        "schub",
        "schuf",
        "schur",
        "sechs",
        "segel",
        "segle",
        "segne",
        "sehen",
        "sehne",
        "sehnt",
        "seife",
        "seile",
        "seine",
        "selbe",
        "sende",
        "sengt",
        "senil",
        "senke",
        "sexta",
        "siebe",
        "siebt",
        "siech",
        "siege",
        "siehe",
        "singe",
        "singt",
        "sinke",
        "sinkt",
        "sinnt",
        "sitze",
        "smart",
        "sogar",
        "sogen",
        "sohle",
        "solch",
        "solid",
        "solls",
        "somit",
        "sonor",
        "sonst",
        "sooft",
        "sorgt",
        "sowas",
        "sowie",
        "spare",
        "spart",
        "speie",
        "speit",
        "spiet",
        "spott",
        "spukt",
        "stach",
        "stank",
        "starb",
        "stark",
        "statt",
        "staue",
        "stehe",
        "stehn",
        "steht",
        "steif",
        "steil",
        "stete",
        "still",
        "stolz",
        "stumm",
        "sucht",
        "sudle",
        "suess",
        "summa",
        "surre",
        "surrt",
        "taete",
        "tafle",
        "tagst",
        "tagte",
        "takle",
        "tanze",
        "tanzt",
        "tappe",
        "tappt",
        "tapse",
        "tapst",
        "tatet",
        "tatst",
        "tauen",
        "taufe",
        "tauft",
        "tauge",
        "taugt",
        "taust",
        "taute",
        "teere",
        "teert",
        "teils",
        "teilt",
        "teuer",
        "teure",
        "texte",
        "tilge",
        "tilgt",
        "tippe",
        "tippt",
        "tobte",
        "toene",
        "toent",
        "tolle",
        "tosen",
        "toste",
        "total",
        "toten",
        "toter",
        "totes",
        "trabe",
        "traff",
        "trage",
        "trank",
        "traue",
        "traut",
        "trink",
        "trotz",
        "trugt",
        "tuend",
        "tunke",
        "tunkt",
        "tupfe",
        "turne",
        "turnt",
        "tut's",
        "tuten",
        "tutet",
        "tutti",
        "ueben",
        "uebst",
        "uebte",
        "ulkig",
        "ulkst",
        "umher",
        "umhin",
        "umsah",
        "umtun",
        "umzog",
        "unbar",
        "unfei",
        "ungut",
        "unser",
        "unten",
        "unter",
        "vagen",
        "vager",
        "vital",
        "vorab",
        "voran",
        "vorig",
        "vorne",
        "wache",
        "wacht",
        "waegt",
        "waere",
        "wagst",
        "wagte",
        "walle",
        "wallt",
        "walze",
        "walzt",
        "wanke",
        "wankt",
        "warme",
        "warnt",
        "warte",
        "waten",
        "water",
        "watet",
        "weben",
        "webte",
        "wecke",
        "weckt",
        "weder",
        "wedle",
        "wegen",
        "wehre",
        "wehrt",
        "wehte",
        "weich",
        "weils",
        "weine",
        "weint",
        "weiss",
        "weist",
        "weite",
        "welch",
        "welkt",
        "wenig",
        "wenns",
        "werbt",
        "werde",
        "werfe",
        "wesen",
        "wetze",
        "wetzt",
        "wicht",
        "wider",
        "widme",
        "wiegt",
        "wilde",
        "wimme",
        "winde",
        "winkt",
        "wippt",
        "wir's",
        "wirke",
        "wirkt",
        "wisse",
        "wisst",
        "wisst",
        "wogst",
        "woher",
        "wohlt",
        "wollt",
        "womit",
        "worin",
        "worum",
        "wrang",
        "wuchs",
        "wunde",
        "wurde",
        "wurme",
        "wurmt",
        "wusch",
        "zahle",
        "zahlt",
        "zanke",
        "zankt",
        "zarte",
        "zehrt",
        "zeige",
        "zeigt",
        "zerrt",
        "zeugt",
        "ziehe",
        "zielt",
        "zieme",
        "ziemt",
        "ziere",
        "ziert",
        "zirpt",
        "zivil",
        "zogen",
        "zoget",
        "zogst",
        "zotig",
        "zucke",
        "zuckt",
        "zudem",
        "zuege",
        "zugab",
        "zumal",
        "zupfe",
        "zupft",
        "zusah",
        "zwang",
        "zweit",
        "Achse",
        "Adler",
        "After",
        "Ahorn",
        "Alter",
        "Angel",
        "Anker",
        "Apfel",
        "Armee",
        "Armel",
        "Artzt",
        "Asche",
        "Asien",
        "Atzen",
        "Backe",
        "Beere",
        "Beruf",
        "Besen",
        "Beule",
        "Biene",
        "Birne",
        "Blase",
        "Blatt",
        "Blitz",
        "Block",
        "Blume",
        "Boden",
        "Bogen",
        "Bohne",
        "Bombe",
        "Brett",
        "Brief",
        "Brust",
        "Buero",
        "Bucht",
        "Busch",
        "Busen",
        "Chlor",
        "Dampf",
        "Datum",
        "Dauer",
        "Decke",
        "Dicke",
        "Draht",
        "Drama",
        "Dunst",
        "Ebene",
        "Eiche",
        "Eimer",
        "Eisen",
        "Engel",
        "Erbse",
        "Erdol",
        "Ernte",
        "Essen",
        "Essig",
        "Faden",
        "Fahne",
        "Fahrt",
        "Falle",
        "Farbe",
        "Faser",
        "Faust",
        "Feder",
        "Feige",
        "Feile",
        "Feind",
        "Ferne",
        "Ferse",
        "Feuer",
        "Fisch",
        "Fleck",
        "Fluss",
        "Folge",
        "Frage",
        "Fremd",
        "Fuchs",
        "Gabel",
        "Geist",
        "Gnade",
        "Griff",
        "Grund",
        "Gummi",
        "Gurke",
        "Haare",
        "Hacke",
        "Hafen",
        "Hafer",
        "Hagel",
        "Haken",
        "Haupt",
        "Hebel",
        "Heute",
        "Hilfs",
        "Hoehe",
        "Hohle",
        "Holle",
        "Honig",
        "Hotel",
        "Hufte",
        "Hugel",
        "Hulse",
        "Human",
        "Insel",
        "Jacke",
        "Junge",
        "Juwel",
        "Kaese",
        "Kabel",
        "Kafig",
        "Kamel",
        "Kamin",
        "Kampf",
        "Kanal",
        "Karte",
        "Katze",
        "Kegel",
        "Kehle",
        "Kerbe",
        "Kerze",
        "Kette",
        "Keule",
        "Klotz",
        "Knopf",
        "Kohle",
        "Komet",
        "Konig",
        "Konto",
        "Konus",
        "Kraft",
        "Krahe",
        "Kreis",
        "Kreuz",
        "Krieg",
        "Kuche",
        "Kugel",
        "Kunst",
        "Kurve",
        "Laden",
        "Lager",
        "Laken",
        "Lampe",
        "Lange",
        "Laufe",
        "Laune",
        "Leber",
        "Leder",
        "Licht",
        "Linie",
        "Linse",
        "Lippe",
        "Loewe",
        "Logik",
        "Lunge",
        "Macht",
        "Magen",
        "Makel",
        "Markt",
        "Mauer",
        "Menge",
        "Milch",
        "Minze",
        "Mobel",
        "Monat",
        "Motor",
        "Muell",
        "Muhle",
        "Munze",
        "Musik",
        "Nabel",
        "Nacht",
        "Nadel",
        "Nagel",
        "Natur",
        "Nebel",
        "Neger",
        "Niere",
        "Notiz",
        "Olive",
        "Onkel",
        "Organ",
        "Ozean",
        "Paket",
        "Pedal",
        "Penis",
        "Pfahl",
        "Pfeil",
        "Pferd",
        "Pflug",
        "Photo",
        "Preis",
        "Prosa",
        "Pumpe",
        "Puppe",
        "Quarz",
        "Rache",
        "Ranke",
        "Rauch",
        "Raufe",
        "Recht",
        "Regal",
        "Regel",
        "Regen",
        "Reihe",
        "Rinne",
        "Ritus",
        "Rolle",
        "Rumpf",
        "Saege",
        "Saite",
        "Salat",
        "Samen",
        "Saule",
        "Schaf",
        "Schal",
        "Scham",
        "Schub",
        "Schuh",
        "Seele",
        "Segel",
        "Sehne",
        "Seide",
        "Seife",
        "Seite",
        "Sense",
        "Sesam",
        "Silbe",
        "Skala",
        "Sklav",
        "Socke",
        "Sonne",
        "Sorge",
        "Spass",
        "Speer",
        "Spiel",
        "Spule",
        "Stadt",
        "Stahl",
        "Stamm",
        "Staub",
        "Stein",
        "Stern",
        "Stirn",
        "Stoff",
        "Stolz",
        "Stoss",
        "Stuck",
        "Stuhl",
        "Sturm",
        "Sumpf",
        "Suppe",
        "Tabak",
        "Tante",
        "Tasse",
        "Taube",
        "Thema",
        "Tiefe",
        "Tiger",
        "Tinte",
        "Tisch",
        "Trane",
        "Traum",
        "Trick",
        "Tulpe",
        "Ubung",
        "Vater",
        "Virus",
        "Vogel",
        "Vokal",
        "Waage",
        "Wachs",
        "Waffe",
        "Wagen",
        "Warme",
        "Watte",
        "Welle",
        "Woche",
        "Wolke",
        "Wolle",
        "Wunde",
        "Wurst",
        "Wuste",
        "Zange",
        "Zelle",
        "Ziege",
        "Zinke",
        "Zunge",
        "Zweig",
        "abend",
        "atmen",
        "bauen",
        "beten",
        "bevor",
        "bitte",
        "blind",
        "bloed",
        "blond",
        "braun",
        "breit",
        "dafur",
        "deise",
        "dicht",
        "durch",
        "eilen",
        "enden",
        "ernst",
        "essen",
        "facts",
        "fegen",
        "flach",
        "geben",
        "gehen",
        "genau",
        "genug",
        "glatt",
        "gross",
        "gruen",
        "haben",
        "heben",
        "holen",
        "horen",
        "huten",
        "immer",
        "inner",
        "jagen",
        "jeden",
        "jetzt",
        "keuhl",
        "keine",
        "klein",
        "knien",
        "konen",
        "kurze",
        "lange",
        "leben",
        "lesen",
        "linke",
        "loben",
        "lugen",
        "mager",
        "meist",
        "muede",
        "nackt",
        "nahen",
        "neben",
        "nicht",
        "platt",
        "recht",
        "reich",
        "rufen",
        "ruhig",
        "sagen",
        "sanft",
        "sauer",
        "schon",
        "sechs",
        "sehen",
        "selbe",
        "slank",
        "solid",
        "spaet",
        "spitz",
        "starr",
        "steif",
        "still",
        "stumm",
        "suess",
        "toten",
        "uebel",
        "ueber",
        "unten",
        "unter",
        "viele",
        "warum",
        "weben",
        "wegen",
        "wehen",
        "weich",
        "weise",
        "weiss",
        "wenig",
        "Abbau",
        "Abruf",
        "Abzug",
        "Achse",
        "Addon",
        "Adern",
        "Adria",
        "Akkus",
        "Akten",
        "Aktiv",
        "Aktor",
        "Allee",
        "Alles",
        "Amiga",
        "Anbei",
        "Anker",
        "Anllo",
        "Anruf",
        "Anzug",
        "Armen",
        "Arten",
        "Ascii",
        "Atari",
        "Atmen",
        "Augen",
        "Autor",
        "Backe",
        "Bande",
        "Barke",
        "Basar",
        "Bauch",
        "Baums",
        "Becon",
        "Beere",
        "Beide",
        "Beine",
        "Beleg",
        "Berge",
        "Bernd",
        "Beruf",
        "Beton",
        "Beute",
        "Bevor",
        "Bezug",
        "Bibel",
        "Bitte",
        "Blase",
        "Blatt",
        "Blaue",
        "Blech",
        "Blick",
        "BlosS",
        "Blume",
        "Boeck",
        "Boden",
        "Boewe",
        "Bombe",
        "Boote",
        "Bosse",
        "Boten",
        "Boxen",
        "Brand",
        "Braue",
        "Breit",
        "Brote",
        "Bruch",
        "Brust",
        "Buero",
        "Buben",
        "Busen",
        "Busse",
        "ConM4",
        "Conm4",
        "Creme",
        "Dabei",
        "Daher",
        "Damen",
        "Damit",
        "Dampf",
        "Danke",
        "Darin",
        "Datei",
        "Daten",
        "Datex",
        "Datum",
        "Dauer",
        "Davon",
        "Davor",
        "Decke",
        "Deren",
        "Dicke",
        "Diebe",
        "Diese",
        "Dinge",
        "Diwan",
        "Dolch",
        "Donau",
        "Dongl",
        "Dorfe",
        "Draht",
        "Dreck",
        "Druck",
        "Duese",
        "Dumme",
        "Dunst",
        "Durch",
        "Durst",
        "Ebene",
        "Ecken",
        "Ehren",
        "Eimer",
        "Einem",
        "Einen",
        "Einer",
        "Eines",
        "Einst",
        "Eisen",
        "Eliza",
        "Epoxi",
        "Eprom",
        "Epson",
        "Ernte",
        "Erste",
        "Etage",
        "Faden",
        "Fahne",
        "Fahrt",
        "Falle",
        "Falte",
        "Farbe",
        "Faure",
        "Feder",
        "Fehlt",
        "Feind",
        "Felds",
        "Fibel",
        "Figur",
        "Filme",
        "Firma",
        "Fisch",
        "Flach",
        "Floez",
        "Fluid",
        "Foehn",
        "Folge",
        "Folie",
        "Forst",
        "Fotos",
        "Frack",
        "Frage",
        "Freie",
        "Frist",
        "Frueh",
        "Fugen",
        "Fuhre",
        "Funke",
        "FusSe",
        "Gabel",
        "Gaben",
        "Gabun",
        "Gange",
        "Ganze",
        "Gasen",
        "Geben",
        "Geber",
        "Gebot",
        "Gegen",
        "Gehen",
        "Geist",
        "Genau",
        "Gerne",
        "Geste",
        "Glanz",
        "Glied",
        "Greis",
        "Griff",
        "GrosS",
        "Gruen",
        "Grube",
        "Grund",
        "GrusS",
        "Guete",
        "Gummi",
        "Gunst",
        "Gutes",
        "Haelt",
        "Haare",
        "Haben",
        "Hafen",
        "Halde",
        "Halle",
        "Hallo",
        "Halme",
        "Hamed",
        "Hanke",
        "Haube",
        "Hauch",
        "Haupt",
        "Hause",
        "Hebel",
        "Heben",
        "Hecke",
        "Hefte",
        "Helme",
        "Herrn",
        "Herst",
        "Heute",
        "Hilfe",
        "Hilfs",
        "Hinzu",
        "Hitze",
        "Hoehe",
        "Hobel",
        "Hocke",
        "Hoden",
        "Hoher",
        "Honig",
        "Hosen",
        "Huebe",
        "Hubes",
        "Hupen",
        "Ideen",
        "Ihnen",
        "Ihrem",
        "Ihren",
        "Ihrer",
        "Ihres",
        "Ikone",
        "Immer",
        "Innen",
        "Insel",
        "Irren",
        "Jacke",
        "Jahre",
        "Jause",
        "Jedem",
        "Jeder",
        "Jedes",
        "Jetzt",
        "Joerg",
        "Joerg",
        "Jubel",
        "Juwel",
        "KDnet",
        "KEINE",
        "Kabel",
        "Kamin",
        "Kampf",
        "Kanal",
        "Kante",
        "Kappe",
        "Karos",
        "Karre",
        "Karte",
        "Kasse",
        "Kater",
        "Katze",
        "Kbyte",
        "Kdnet",
        "Kegel",
        "Kehle",
        "Keine",
        "Kerbe",
        "Kerle",
        "Kette",
        "Keule",
        "Kinex",
        "Kinos",
        "Kippe",
        "Kiste",
        "Klage",
        "Klang",
        "Klaps",
        "Kleid",
        "Klenk",
        "Knabe",
        "Knien",
        "Knopf",
        "Kohle",
        "Konto",
        "Koord",
        "Kopie",
        "Kraus",
        "Kreis",
        "Kreuz",
        "Krieg",
        "Krimi",
        "Kuehn",
        "Kufen",
        "Kunde",
        "Kunst",
        "Kurve",
        "LCA's",
        "Laedt",
        "Laerm",
        "Lader",
        "Lagen",
        "Lampe",
        "Lande",
        "Lanze",
        "Latte",
        "Laufe",
        "Laune",
        "Leben",
        "Leber",
        "Leder",
        "Leere",
        "Lehle",
        "Leine",
        "Lenze",
        "Lerne",
        "Lesen",
        "Letzt",
        "Leute",
        "Licht",
        "Lider",
        "Liebe",
        "Liegt",
        "Linie",
        "Linkt",
        "Lippe",
        "Liste",
        "Litze",
        "Loewe",
        "Lochs",
        "Logik",
        "Lueke",
        "Luxus",
        "MByte",
        "MIPRO",
        "MSDOS",
        "Maerz",
        "Macht",
        "Magen",
        "Magst",
        "Makro",
        "Maler",
        "Manne",
        "Marke",
        "Markt",
        "Marne",
        "MasSe",
        "Maske",
        "Masse",
        "Mbyte",
        "Meere",
        "Meigs",
        "Meine",
        "Meist",
        "Mende",
        "Menge",
        "Menue",
        "Menue",
        "Merke",
        "Messe",
        "Miene",
        "Miete",
        "Mikro",
        "Mipro",
        "Mitte",
        "Moden",
        "Modul",
        "Mokka",
        "Monat",
        "Morde",
        "Muehe",
        "Muffe",
        "Munix",
        "Musik",
        "NAMUR",
        "NICHT",
        "Naehe",
        "Nacht",
        "Nadel",
        "Nagel",
        "Namen",
        "Namur",
        "Nasen",
        "Natur",
        "Nebel",
        "Neben",
        "Neffe",
        "Neger",
        "Nehmt",
        "Neige",
        "Netze",
        "Neuem",
        "Neuer",
        "Neues",
        "Nicht",
        "Nimmt",
        "Notar",
        "Noten",
        "Notiz",
        "Nuten",
        "Oelen",
        "Obhut",
        "Ochse",
        "Ocram",
        "Offen",
        "Ohren",
        "Oktan",
        "Opfer",
        "Orgie",
        "Otmar",
        "Ouput",
        "Paare",
        "Pacht",
        "Paket",
        "Palme",
        "Panik",
        "Papst",
        "Pegel",
        "Pelze",
        "Pfade",
        "Pfeil",
        "Pferd",
        "Pfiff",
        "Pfund",
        "Phono",
        "Piezo",
        "Piste",
        "Pixel",
        "Platz",
        "Polen",
        "Poren",
        "Porno",
        "Potis",
        "Prinz",
        "Puder",
        "Pumpe",
        "Punkt",
        "Puppe",
        "Quais",
        "Quarz",
        "Quere",
        "Quirl",
        "RS232",
        "RS422",
        "Rades",
        "Rampe",
        "Rande",
        "Raten",
        "Rauch",
        "Raupe",
        "Recht",
        "Recke",
        "Regen",
        "Regie",
        "Reihe",
        "Reine",
        "Reise",
        "Reste",
        "Riedl",
        "Riese",
        "Ringe",
        "Risse",
        "Rmtos",
        "Rodel",
        "Rohre",
        "Rolle",
        "Rufen",
        "Rugby",
        "Runde",
        "SKK24",
        "SKK34",
        "SM124",
        "Saals",
        "Sache",
        "Salat",
        "Saldo",
        "Schal",
        "Schau",
        "Schub",
        "Seele",
        "Segen",
        "Seide",
        "Seife",
        "Seile",
        "Seins",
        "Seite",
        "Sende",
        "Serie",
        "Setze",
        "Setzt",
        "Sicht",
        "Siehe",
        "Silan",
        "Sinne",
        "Skala",
        "Socke",
        "Sohle",
        "Somit",
        "Sonne",
        "Sonst",
        "Sorge",
        "Sorte",
        "Sowie",
        "SpasS",
        "Spule",
        "Stadt",
        "Stamm",
        "Statt",
        "Staub",
        "Stege",
        "Steht",
        "Stets",
        "Stich",
        "Stiel",
        "Stift",
        "Stirb",
        "Stirn",
        "Streb",
        "Stufe",
        "Stuhl",
        "Sturz",
        "Suche",
        "Summe",
        "Suppe",
        "Szene",
        "Tafel",
        "Tagen",
        "Tages",
        "Takte",
        "Tasse",
        "Taten",
        "Teich",
        "Teile",
        "Teils",
        "Tetra",
        "Texte",
        "Thema",
        "Thron",
        "Thurn",
        "Tiefe",
        "Tinte",
        "Tisch",
        "Titel",
        "Toene",
        "Trafo",
        "Traum",
        "Treff",
        "Trenn",
        "Tritt",
        "Troja",
        "Trost",
        "Trotz",
        "Tuete",
        "Turbo",
        "Turck",
        "Typen",
        "Typus",
        "Ueber",
        "UdSSR",
        "Ufern",
        "Uhren",
        "Umbau",
        "Umweg",
        "Unser",
        "Unter",
        "Uralt",
        "VT100",
        "Valvo",
        "Vasen",
        "Vater",
        "Vergl",
        "Video",
        "Viele",
        "Visum",
        "Volle",
        "Waere",
        "Waage",
        "Wachs",
        "Wagen",
        "Walze",
        "Wange",
        "Warte",
        "Warum",
        "Watte",
        "Wegen",
        "Weges",
        "Weile",
        "Weine",
        "WeisS",
        "Weise",
        "Weite",
        "Welle",
        "Werke",
        "Werte",
        "Wesen",
        "Weste",
        "Whitt",
        "Wiese",
        "Wieso",
        "Wille",
        "Willi",
        "Winde",
        "Wobei",
        "Woche",
        "Wolke",
        "Womit",
        "Wonne",
        "Worte",
        "Woyna",
        "Wucht",
        "Wunde",
        "Xenix",
        "Yampa",
        "ZEILE",
        "Zakel",
        "Zange",
        "Zeche",
        "Zeigt",
        "Zeile",
        "Zelle",
        "Zeter",
        "Zeugs",
        "Ziele",
        "Ziels",
        "Zilog",
        "Zitat",
        "Zudem",
        "Zunge",
        "Zuvor",
        "Zwang",
        "Zweck",
        "Zwerg",
        "abzog",
        "adrig",
        "ahmen",
        "aktiv",
        "akute",
        "alice",
        "allem",
        "allen",
        "alles",
        "allzu",
        "altem",
        "alten",
        "anbei",
        "antik",
        "armen",
        "armer",
        "armes",
        "artig",
        "atmen",
        "atmet",
        "bauen",
        "baute",
        "beben",
        "behob",
        "beide",
        "bekam",
        "beste",
        "beuge",
        "beugt",
        "bevor",
        "bezog",
        "biegt",
        "biete",
        "birgt",
        "bitte",
        "blasS",
        "blieb",
        "bloed",
        "blosS",
        "boese",
        "bohrt",
        "boten",
        "braun",
        "breit",
        "bunte",
        "conm4",
        "dabei",
        "daher",
        "dahin",
        "damit",
        "danke",
        "daran",
        "darin",
        "darum",
        "davon",
        "davor",
        "deckt",
        "deine",
        "denen",
        "denke",
        "denkt",
        "deren",
        "derer",
        "desto",
        "deute",
        "dicht",
        "dicke",
        "dient",
        "diese",
        "drauf",
        "drehe",
        "dreht",
        "dritt",
        "droht",
        "duenn",
        "dumme",
        "durch",
        "ebene",
        "echte",
        "edlem",
        "egsam",
        "eigen",
        "eilig",
        "einem",
        "einen",
        "einer",
        "eines",
        "einst",
        "eisig",
        "eklig",
        "emsig",
        "endet",
        "engen",
        "enger",
        "engte",
        "enorm",
        "erden",
        "ergab",
        "erhob",
        "erlag",
        "ernst",
        "erste",
        "essen",
        "etwas",
        "eurem",
        "ewige",
        "exakt",
        "exine",
        "fahre",
        "falle",
        "fasSt",
        "fasse",
        "faxen",
        "fehlt",
        "feine",
        "ferne",
        "feste",
        "fette",
        "fiele",
        "finde",
        "fixen",
        "flach",
        "fleht",
        "flugs",
        "folge",
        "folgt",
        "formt",
        "frage",
        "fragt",
        "frech",
        "freie",
        "fremd",
        "fromm",
        "frueh",
        "fuege",
        "fuegt",
        "fuenf",
        "fuers",
        "fusSt",
        "gaebe",
        "ganze",
        "geben",
        "gegen",
        "gehen",
        "gehst",
        "gehts",
        "gelbe",
        "genau",
        "genug",
        "gerne",
        "getan",
        "gibst",
        "glatt",
        "graue",
        "grobe",
        "grosS",
        "gruen",
        "guten",
        "guter",
        "gutes",
        "haelt",
        "haben",
        "halbe",
        "hallo",
        "hallt",
        "halte",
        "harte",
        "hasse",
        "hatte",
        "heben",
        "heisS",
        "helle",
        "hellt",
        "hemmt",
        "herab",
        "heran",
        "herum",
        "heuer",
        "heule",
        "heute",
        "hielt",
        "hilft",
        "hinab",
        "hinkt",
        "hinzu",
        "hoere",
        "hoert",
        "hockt",
        "hoffe",
        "hohem",
        "hohen",
        "hoher",
        "hohes",
        "hohle",
        "holen",
        "hurra",
        "huste",
        "ihnen",
        "ihrem",
        "ihren",
        "ihrer",
        "ihres",
        "immer",
        "indem",
        "innen",
        "innig",
        "irrem",
        "irren",
        "jedem",
        "jeden",
        "jeder",
        "jedes",
        "jeher",
        "jenem",
        "jenen",
        "jener",
        "jenes",
        "jetzt",
        "junge",
        "kBaud",
        "kByte",
        "kaeme",
        "kalte",
        "kamen",
        "kamst",
        "kauft",
        "kdnet",
        "kehrt",
        "keine",
        "kenne",
        "kennt",
        "klamm",
        "klare",
        "klaut",
        "klebt",
        "klein",
        "knapp",
        "kocht",
        "komme",
        "kommt",
        "koste",
        "kraft",
        "krank",
        "kraus",
        "kroch",
        "krumm",
        "kuehl",
        "kurze",
        "laedt",
        "lache",
        "lacht",
        "lagen",
        "lange",
        "lasse",
        "laufe",
        "leben",
        "lebst",
        "leere",
        "leert",
        "legen",
        "legst",
        "legte",
        "lehne",
        "lehnt",
        "leise",
        "leite",
        "lerne",
        "lesen",
        "liebe",
        "liebt",
        "liege",
        "liegt",
        "liesS",
        "linke",
        "loest",
        "loggt",
        "lohnt",
        "lokal",
        "losem",
        "luegt",
        "mache",
        "macht",
        "mager",
        "malen",
        "marco",
        "meine",
        "meint",
        "meist",
        "merke",
        "merkt",
        "misSt",
        "moege",
        "muede",
        "musSt",
        "nackt",
        "nahen",
        "neben",
        "nebst",
        "nehme",
        "nehmt",
        "neigt",
        "nennt",
        "nette",
        "netto",
        "neuem",
        "neuen",
        "neuer",
        "neues",
        "nicht",
        "nicke",
        "nickt",
        "nimmt",
        "nutze",
        "nutzt",
        "oedet",
        "oelig",
        "obere",
        "obige",
        "offen",
        "packe",
        "packt",
        "parat",
        "pasSt",
        "platt",
        "pocht",
        "polig",
        "puren",
        "quitt",
        "ragen",
        "rasch",
        "rasen",
        "raten",
        "raubt",
        "rauhe",
        "recht",
        "reckt",
        "redet",
        "reine",
        "reise",
        "reizt",
        "renne",
        "rennt",
        "rings",
        "rinnt",
        "rohem",
        "rollt",
        "rosig",
        "rotem",
        "roten",
        "roter",
        "rufen",
        "ruhen",
        "ruhig",
        "runde",
        "sackt",
        "sagen",
        "sagst",
        "sagte",
        "sahen",
        "sanft",
        "saugt",
        "schal",
        "schob",
        "schon",
        "sechs",
        "sehen",
        "seien",
        "selig",
        "sende",
        "senkt",
        "setze",
        "setzt",
        "siehe",
        "sieht",
        "singt",
        "sinke",
        "sinkt",
        "sitze",
        "sitzt",
        "sogar",
        "solch",
        "somit",
        "sonst",
        "sooft",
        "sorgt",
        "sowas",
        "sowie",
        "spaet",
        "spart",
        "spukt",
        "starb",
        "starr",
        "statt",
        "stehe",
        "steht",
        "steif",
        "stets",
        "stieg",
        "stolz",
        "stumm",
        "suche",
        "sucht",
        "surrt",
        "tanzt",
        "teile",
        "teils",
        "teilt",
        "teste",
        "teuer",
        "teure",
        "tippt",
        "toben",
        "tolle",
        "trete",
        "treue",
        "tritt",
        "trotz",
        "uebel",
        "ueber",
        "ueble",
        "uebte",
        "umher",
        "umhin",
        "unser",
        "unter",
        "uralt",
        "vagen",
        "vergl",
        "viele",
        "viert",
        "volle",
        "vorab",
        "voran",
        "vorne",
        "waere",
        "wacht",
        "wagen",
        "wahre",
        "waren",
        "warme",
        "warnt",
        "warst",
        "warte",
        "warum",
        "watet",
        "weder",
        "wegen",
        "wehen",
        "wehte",
        "weich",
        "weisS",
        "weise",
        "weist",
        "weite",
        "welch",
        "wende",
        "wenig",
        "werde",
        "werfe",
        "wiegt",
        "wieso",
        "wilde",
        "winde",
        "winkt",
        "wirft",
        "wirke",
        "wirkt",
        "wirst",
        "wobei",
        "woher",
        "wohin",
        "wohne",
        "wohnt",
        "wolle",
        "wollt",
        "womit",
        "woran",
        "wovon",
        "wurde",
        "xload",
        "zahlt",
        "zarte",
        "zeige",
        "zeigt",
        "zerrt",
        "zeugt",
        "ziehe",
        "zieht",
        "ziele",
        "ziemt",
        "zucke",
        "zuckt",
        "zudem",
        "zumal",
        "zupfe",
        "zuvor",
        "zweit"
    ]
};
export default words;
